(function() {
  const graph_links = document.getElementsByClassName('graph_link');
	for (let i = 0; i < graph_links.length; i++) {
		graph_links[i].addEventListener('click', showMenu, false);
    graph_links[i].addEventListener('touchstart', showMenu, false);
	}

	function showMenu(e) {
    e.preventDefault();
    const clicked_link = this;
		const link_list = this.parentElement.children[1];

		if (link_list.style.display === 'block') {
			link_list.style.display = 'none';
			for (let i = 0; i < graph_links.length; i++) {
				graph_links[i].parentElement.style.visibility = 'visible';
			}
		} else {
			link_list.style.display = 'block';
			for (let i = 0; i < graph_links.length; i++) {
				if (clicked_link !== graph_links[i]) {
					graph_links[i].parentElement.style.visibility = 'hidden';
				}
			}
		}
	}
})();
